import React from "react"
import "./App.css"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import BasicTabs from "./components/tabs"

function App() {
  return (
    <div className="App">
      <div className="banner"></div>
      <BasicTabs />
    </div>
  )
}

export default App
