import * as React from "react"
import PropTypes from "prop-types"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          textColor="secondary"
          indicatorColor="secondary"
          sx={{
            "& button": { textColor: "orange" },
          }}
          aria-label="basic tabs example"
        >
          <Tab label="Listen" {...a11yProps(0)} />
          <Tab label="About" {...a11yProps(1)} />
          <Tab label="Contact" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} className="tabPanel">
        <div className="listen">
          <div className="player">
            <iframe
              src="https://open.spotify.com/embed/show/10xnjM6fkzWyhuUvfOxuoK?utm_source=generator&theme=0&t=0"
              width="100%"
              height="352"
              frameBorder="0"
              allowFullScreen=""
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
            ></iframe>
          </div>
          <h3>Listen Now</h3>
          <div className="content">
            <a href="https://fountain.fm/show/1XGdMnd90oqi57oGSM90">Fountain</a>
            <a href="https://podcasts.apple.com/au/podcast/reject-the-frame/id1674672703">
              Apple
            </a>
            <a href="https://open.spotify.com/show/10xnjM6fkzWyhuUvfOxuoK?si=ec50293ff38a4f0c">
              Spotify
            </a>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1} className="tabPanel">
        <div className="background"></div>
        <div className="content1">
          For the last number of years it has been increasingly obvious
          <br />
          that i had to do something to contribute to bitcoin... which is to say
          humanity.
          <br />
          <br />
          <i>Because we need bitcoin.</i>
          <br />
          We all do.
          <br />
          <br />
          Even those who don't realise it yet. Especially those who don't
          realise it.
          <br />
          <br />
          So these conversations are had with that in mind.
          <br />
          <br />
          Your thoughts, suggestions and feedback are always welcome, so please
          don't hesitate to get in touch.
          <br />
          <br />- AK
        </div>
      </TabPanel>
      <TabPanel value={value} index={2} className="tabPanel">
        <div className="content2">
          <a
            href="http://damus.io/npub1xjfd6s75j63r0azyrlvqrag83d34gtp7zk8la2grevpq5xh5llws9lwuey"
            className="highlight"
          >
            nostr
          </a>
          <a href="mailto:ak@rejecttheframe.xyz" className="highlight1">
            pod(at)rejecttheframe(dot)xyz
          </a>
        </div>
      </TabPanel>
      <div className="footer">
        <a href="http://rejecttheframe.xyz/bitcoin.pdf" className="whitepaper">Bitcoin.pdf</a>
        <a href="http://rejecttheframe.xyz/bitcoin.pdf" className="whitepaper">31/10/2008</a>
      </div>
    </Box>
  )
}
